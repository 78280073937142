let checkLoginStatusPromise;

export default function isUserLoggedIn() {
  if (window.loggedInUser && window.tenant) {
    return Promise.resolve(true);
  } else if (checkLoginStatusPromise) {
    return checkLoginStatusPromise;
  } else {
    checkLoginStatusPromise = SystemJS.import("cp-client-auth!sofe")
      .then((authModule) => authModule.default.checkLoginStatus())
      .then((result) => !!result.isLoggedIn);

    return checkLoginStatusPromise;
  }
}
