import sentryBootstrap from "./sentry.bootstrap.js";
import sofeInspectorBootstrap from "./sofe-inspector.bootstrap.js";
import rootRouteBootstrap from "./root-route.bootstrap.js";
import { dataDogRum } from "./data-dog-rum";
import { handleError } from "../handle-error";

export default function bootstrapSpa() {
  let appFailure = false;

  const blockingPromises = [rootRouteBootstrap()];

  const nonBlockingPromises = [
    Promise.resolve(sentryBootstrap()),
    sofeInspectorBootstrap(),
    dataDogRum(),
  ];

  Promise.all(nonBlockingPromises)
    .then(() => {
      console.info(
        "The non-blocking bootstrap process for mobile-root-config has completed"
      );
    })
    .catch((err) => {
      if (!appFailure) {
        nonBlockingPromises[0] // Make sure sentry is initialized
          .then(() => handleError(err))
          .catch(() => handleError(err)); // Sentry will be initialized even in an error caused by loggedInUser failing
      }
    });

  return Promise.all(blockingPromises).catch((err) => {
    appFailure = true;
    nonBlockingPromises[0] // Make sure sentry is initialized
      .then(() => handleError(err))
      .catch(() => handleError(err)); // Sentry will be initialized even in an error caused by loggedInUser failing
  });
}
