import isUserLoggedIn from "./is-user-logged-in.bootstrap.js";
import {
  onLoginPage,
  preAuthUnauthorized,
  preAuthUI,
} from "../child-app-active.functions.js";

export default function () {
  return isUserLoggedIn().then((sessionIsValid) => {
    const exemptPages = [onLoginPage, preAuthUnauthorized];
    const loginRequired = !exemptPages.some((routeCheck) =>
      routeCheck(window.location)
    );
    if (!sessionIsValid && loginRequired) {
      // The user is not logged in
      if (preAuthUI(location) && !preAuthUnauthorized(window.location)) {
        // pre-auth cant use the normal login page
        window.location.href = `${window.location.origin}/l/unauthorized`;
      } else {
        // Redirect to login page, but only if they aren't already on a login page
        const encodedUrl = window.encodeURIComponent(window.location.href);
        const baseRoute = window.appIsMobile ? "m" : "#";
        const { hash, pathname } = window.location;
        const params =
          (hash === "" || hash === "#" || hash === "#/") &&
          (pathname === "/" || pathname === "/m" || pathname === "/m/")
            ? ""
            : `?redirect_url=${encodedUrl}`;
        window.location.href = `${window.location.origin}/${baseRoute}/login${params}`;
      }
    }
  });
}
