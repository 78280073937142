export default function () {
  return new Promise((resolve, reject) => {
    if (window.localStorage.getItem("sofe-inspector")) {
      const intercomImpact = 80;

      const iconPositionLeft = window.innerWidth - 70 - intercomImpact;
      const iconPositionTop = window.innerHeight - 70;

      SystemJS.import("sofe-inspector!sofe")
        .then((inspector) =>
          (inspector.default || inspector)({
            iconPositionLeft,
            iconPositionTop,
          })
        )
        .then(resolve)
        .catch(reject);
    } else {
      resolve();
    }
  });
}
